<template>
    <div>
        <div v-if="!checkNull() || spendOverrideIsEnabled">
            <span class="empty-field">|</span>
        </div>
        <div v-else>
            {{ getPath() }}
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapState } from 'vuex';
export default {
    name: 'FacebookAdvertisingCell',
    props: {
        isNullPath: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        itemPath: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            canBeDisabled: this.itemPath.match('spend|cp|cost'),
            isNoSpend: this.item.dealer.configuration?.facebook_ad_spend_override?.type === 'no_spend'
        };
    },
    computed: {
        ...mapState({
            spendOverrideDisable: (state) => state.metrics.spendOverrideDisable,
        }),
        spendOverrideIsEnabled() {
            // account has no configuration for override, show spend metrics
            if(!this.item?.dealer?.configuration?.facebook_ad_spend_override) {
                return false;
            }

            // account has configuration and checkbox is false - show all spend metrics
            // account has configuration and checkbox is true - show no spend metrics
            return this.canBeDisabled && this.isNoSpend && !this.spendOverrideDisable;
        },
    },
    methods: {
        checkNull() {
            return !!get(this.item, this.isNullPath);
        },
        getPath() {
            return get(this.item, this.itemPath);
        }
    }
};
</script>

<style lang="scss" scoped>
.empty-field {
    transform: rotate(38deg);
    display: inline-block;
    font-size: 17px;
    font-weight: 300;
    color: $gray-light;
}
</style>
